import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE_EMAIL from "../../assets/images/setup/setup-trackcode-email.png"
import SETUP_CELERY_PASTE from "../../assets/images/setup/setup-celery-paste.png"
import SETUP_CELERY_NOTIFICATIONS from "../../assets/images/setup/setup-celery-notifications.png"
import SETUP_CELERY_TIMESTAMP from "../../assets/images/setup/setup-celery-timestamp.png"
import SETUP_CELERY_BCC from "../../assets/images/setup/setup-celery-bcc.png"

const metadata = {
  title: "Connect Celery to ReferralCandy",
}

const CelerySetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for Celery</h1>
        <p className={fontType.b1}>
          Step 1: Setting up your ReferralCandy Account
        </p>
        <ol type="1">
          <li>
            Head over to the{" "}
            <a href="https://my.referralcandy.com/signup#celery">
              sign up page
            </a>{" "}
            to register for a new ReferralCandy account.
          </li>
          <li>
            Go through the account setup wizard. At the integration step, note
            the tracking code and email address for your referral program. You
            will need them for the next two parts.
          </li>
          <br />
          <br />
          <img src={TRACKINGCODE_EMAIL} alt="Tracking Code Email" />
          <li>Complete the rest of the setup wizard.</li>
        </ol>
        <p className={fontType.b1}>
          Step 2: Adding the tracking code to your Celery store
        </p>
        <ol type="1">
          <li>Log into your Celery dashboard.</li>
          <li>
            Copy and paste the tracking code from Step 2 into the "Settings" >
            "Checkout" > "Confirmation Page Custom Scripts" section. Click the
            "Save" button to save the changes.
          </li>
          <br />
          <br />
          <img src={SETUP_CELERY_PASTE} alt="Setup Celery paste" />
        </ol>
        <p className={fontType.b1}>
          Step 3: Adding a Timestamp to your Celery Order Notification Emails
        </p>
        <ol type="1">
          <li>
            Click "Edit" on your Order Notification Email, located at "Settings"
            > "Notifications" > "Buyer Email Notications" > "Order" > "Placed" >
            "Edit".
          </li>
          <br />
          <br />
          <img
            src={SETUP_CELERY_NOTIFICATIONS}
            alt="Setup Celery notification"
          />
          <li>Copy and paste this timestamp code anywhere within the email.</li>
          <br />
          <br />
          <img src={SETUP_CELERY_TIMESTAMP} alt="Setup Celery Timestamp" />
        </ol>
        <p className={fontType.b1}>
          Step 4: Sending Your Celery Order Notification Emails to ReferralCandy
        </p>
        <ol type="1">
          <li>
            Within the same Order Notification Email Edit modal, paste the
            ReferralCandy email from Step 2 into the "BCC Email" input box.
          </li>
          <br />
          <br />
          <img src={SETUP_CELERY_BCC} alt="Setup Celery BCC" />
          <li>Click "Save", and you’re done!</li>
        </ol>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default CelerySetup
